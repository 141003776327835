<template lang="pug">
v-container(fluid).azul
  v-row.justify-center.align-center
    v-container(style='margin-top: 1em')
      v-row.justify-center.align-center
        v-col(cols=12 md=5).justify-center.align-center
          h2.white--text(v-html='laudos_encabezado.título').text-center

  v-row.justify-center.align-center(style="padding-bottom:2rem; padding-left:2rem; padding-right:2rem")
    template(v-for='{caso_arbitral, estado, tribunal, fecha, demandante, boton, archivo, demandado} in paginatedLaudos')
      v-col(cols=12 md=6)
        v-card(outlined style="border: 3px solid #d6a772;")
          v-card-subtitle
            div(style="display: flex; justify-content: space-between; color: black")
              p(style="margin: 0; font-weight: bold;color: black") Caso arbitral:
              p(style="margin-left: 0.5rem;" v-html='caso_arbitral')
              p(style="margin: 0; font-weight: bold; margin-left: 1rem;") Estado del caso:
              p(style="margin-left: 0.5rem;" v-html='estado')

            // Línea divisora
            div(style="height: 2px; background-color: #d6a772; margin-bottom: 1rem")

            div(style="display: flex; justify-content: start; color: black")
              p(style="margin: 0; font-weight: bold;color: black") Tribunal designado:
              p(style="margin-left: 0.5rem;" v-html='tribunal')
            div(style="display: flex; justify-content: start; color: black")
              p(style="margin: 0; font-weight: bold;color: black") Fecha de Constitución:
              p(style="margin-left: 0.5rem;" v-html='fecha')
            div(style="display: flex; color: black")
              p(style="margin: 0; font-weight: bold;") Demandante:
              p(style="margin-left: 0.5rem;" v-html='demandante')
            div(style="display: flex; color: black")
              p(style="margin: 0; font-weight: bold;") Demandado:
              p(style="margin-left: 0.5rem;" v-html='demandado')
        
          v-card-actions
            v-spacer
            v-btn(:href='archivo' target='_blank' id="ar" outlined class="button d-md-none")
              v-icon(left) fas fa-file-pdf
              span Descargar archivo
            v-btn(:href='archivo' target='_blank' class="primary d-none d-md-flex")
              v-icon(left) fas fa-file-pdf
              span Descargar archivo
            v-spacer

  v-row.justify-center.align-center
    v-pagination(:length='totalPages' v-model='currentPage')
</template>

<script>
export default {
  data() {
    return {
      currentPage: 1,
      itemsPerPage: 6,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.laudos_encabezado.listado.length / this.itemsPerPage);
    },
    paginatedLaudos() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.laudos_encabezado.listado.slice(start, end);
    }
  }
};
</script>
